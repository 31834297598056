<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
// import { barChartOptions } from './config'

export default {
  extends: Bar,
  mixins: [reactiveProp],
  // props: ['data', 'options'],
  props: ['options'],
  // data () {
  //   return {
  //     options: barChartOptions
  //   }
  // },
  mounted () {
    // this.renderChart(this.data, this.options)
    this.renderChart(this.chartData, this.options)
  },
  // watch: {
  //   data: function() {
  //     // this._chart.destroy();
  //     this.renderChart(this.data, this.options);
  //     // this.renderLineChart();
  //   }
  // }
}
</script>
